import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Switch from "@components/switch"
import TopButton from "@components/topbutton"
import UnderlineLink from "@styles/UnderlineLink"
import { breakpoints } from "@styles"

import ThinkingFaceEmojiIcon from "@images/emojis/thinking-face.png"

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;

  h1 {
    font-size: 4rem;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.5rem;

    ${breakpoints.mobile`font-size: 1.25rem;`}
    ${breakpoints.smallMobile`font-size: 1.1rem;`}
  }

  ${UnderlineLink} {
    color: var(--blue);
  }
`

const StyledName = styled.div`
  position: absolute;
  top: 25px;
  left: 30px;
  font-weight: 700;
  color: var(--blue);
  font-size: 1.5rem;

  ${breakpoints.mobile`
    top: 20px;
    left: 25px;
    font-size: 1.25rem;
  `}
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Switch />
    <StyledContainer>
      <StyledName>
        <UnderlineLink as={Link} to="/">
          Thibault Ehrhart
        </UnderlineLink>
      </StyledName>
      <img src={ThinkingFaceEmojiIcon} alt="" />
      <h1>Hmmm...</h1>
      <h2>
        Looks like there's nothing here.
        <br />
        Maybe you should go back{" "}
        <UnderlineLink as={Link} to="/">
          home
        </UnderlineLink>
        .
      </h2>
    </StyledContainer>
    <TopButton />
  </Layout>
)

export default NotFoundPage
